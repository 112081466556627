import { Box, CircularProgress, Container, Divider, Typography } from "@material-ui/core";
import useAxios from "axios-hooks";
import * as React from "react";

import { SERVICES_URL } from "../../services/ApiClient/urls";
import ServiceKeyForm from "./components/ServiceKeyForm";
import useStyles from "./styles";

interface Service {
  id: string;
  name: string;
}

const ServiceKeysComponent: React.FC<any> = () => {
  const classes = useStyles();
  const [{ data: services, loading, error }] = useAxios<Service[]>(SERVICES_URL);

  const listExistingServices = (services: Service[]) =>
    services.map(({ id, name }) => (
      <div key={id}>
        <ServiceKeyForm service={name} />
      </div>
    ));

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography align="center" variant="h4" component="h1">
          Service Keys
        </Typography>
      </Box>
      <Box mb={3}>
        <ServiceKeyForm />
      </Box>
      <Divider variant="middle" className={classes.divider} />
      <Box display="flex" flexDirection="column">
        <Box m={2}>
          <Typography variant="h5" component="h2">
            Existing services
          </Typography>
        </Box>
        {error && <span>There was an error</span>}
        {loading ? (
          <Box textAlign="center" mb={3}>
            <CircularProgress />
          </Box>
        ) : (
          Array.isArray(services) && listExistingServices(services)
        )}
      </Box>
    </Container>
  );
};

export default ServiceKeysComponent;
