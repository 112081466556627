import axios from "axios";

import { CONFIG_KEYS_URL_EXTENSION, CONFIG_KEYS_URL_LOCAL, OFFICE_URL_EXTENSION, OFFICE_URL_LOCAL } from "./constants";
import { ConfigKey, OfficeResponse } from "./types";

const getAppBaseUrl = () => {
  let baseURL = process.env.REACT_APP_API_URL;

  if (baseURL === "" || typeof baseURL === "undefined") {
    baseURL = "https://develop-docker.emsclaimsengine.com";
  }

  if (baseURL === "https://uclaim.emsclaimsengine.com") {
    baseURL = "https://schade.uclaim.eu";
  }

  return baseURL;
};

export const createBaseUrl = (link: string) => {
  return getAppBaseUrl() + "/external-api/" + link;
};

export const CONFIG_KEYS_BASE_URL = createBaseUrl(CONFIG_KEYS_URL_EXTENSION) ?? CONFIG_KEYS_URL_LOCAL;
let OFFICE_BASE_URL = createBaseUrl(OFFICE_URL_EXTENSION) ?? OFFICE_URL_LOCAL;

export const createConfigHeaders = (token: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const fetchOffices = (token: string) => {
  const config = createConfigHeaders(token);

  return new Promise<OfficeResponse[]>((resolve, reject) => {
    axios
      .get(`${OFFICE_BASE_URL}`, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchConfigKeys = (token: string) => {
  const config = createConfigHeaders(token);

  return new Promise<ConfigKey[]>((resolve, reject) => {
    axios
      .get(`${CONFIG_KEYS_BASE_URL}`, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteConfigKey = (token: string, input: number) => {
  const config = createConfigHeaders(token);

  const urlEncoded: string = `%7B%22id%22%3A%22${input}%22%7D`;

  return new Promise((resolve, reject) => {
    axios
      .delete(`${CONFIG_KEYS_BASE_URL}/${urlEncoded}`, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
