import React from "react";
import { Link } from "react-router-dom";

interface LoggedOutMenuProps {}

export interface LoggedOutMenuInnerProps extends LoggedOutMenuProps {}

export const LoggedOutMenuComponent: React.FC<LoggedOutMenuInnerProps> = (props) => {
  return (
    <Link style={{ flex: 1, color: "white", textDecoration: "none" }} to="/login">
      Login
    </Link>
  );
};
