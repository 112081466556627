import { createAction } from "redux-actions";

import ApiClient from "../../services/ApiClient/api-client-services";
import { Action } from "../../types/redux";
import { DropoutRawResponse, DropoutRequest, Policy } from "./models";

export enum ActionTypePrefixes {
  DROPOUTS = "DROPOUTS",
  ADD_DROPOUT = "ADD_DROPOUT",
  EDIT_DROPOUT = "EDIT_DROPOUT",
  DELETE_DROPOUT = "DELETE_DROPOUT",
  POLICY_TYPES = "POLICY_TYPES",
}

export enum ActionTypeKeys {
  DROPOUTS_PENDING = "DROPOUTS_PENDING",
  DROPOUTS_FULFILLED = "DROPOUTS_FULFILLED",
  DROPOUTS_REJECTED = "DROPOUTS_REJECTED",
  ADD_DROPOUT_PENDING = "ADD_DROPOUT_PENDING",
  ADD_DROPOUT_FULFILLED = "ADD_DROPOUT_FULFILLED",
  ADD_DROPOUT_REJECTED = "ADD_DROPOUT_REJECTED",
  EDIT_DROPOUT_PENDING = "EDIT_DROPOUT_PENDING",
  EDIT_DROPOUT_FULFILLED = "EDIT_DROPOUT_FULFILLED",
  EDIT_DROPOUT_REJECTED = "EDIT_DROPOUT_REJECTED",
  DELETE_DROPOUT_PENDING = "DELETE_DROPOUT_PENDING",
  DELETE_DROPOUT_FULFILLED = "DELETE_DROPOUT_FULFILLED",
  DELETE_DROPOUT_REJECTED = "DELETE_DROPOUT_REJECTED",
  POLICY_TYPES_PENDING = "POLICY_TYPES_PENDING",
  POLICY_TYPES_FULFILLED = "POLICY_TYPES_FULFILLED",
  POLICY_TYPES_REJECTED = "POLICY_TYPES_REJECTED",
}

interface DropoutPayload {
  data: DropoutRawResponse[];
}

interface EditDropoutPayload {
  config: {
    data: string;
  };
}

interface DeleteDropoutMeta {
  id: number;
}

interface PolicyTypesPayload {
  data: Policy[];
}

const getDroputsExecutor = () => ApiClient.getAllDropouts();

const addDropoutExecutor = (dropout: DropoutRequest) => ApiClient.addDropout(dropout);

const deleteDropoutExecutor = (id: number) => ApiClient.deleteDropout(id);
const deleteMetaCreator = (id: number) => ({ id });

const editDropoutExecutor = (dropout: DropoutRawResponse) => ApiClient.editDropout(dropout);

const getPolicyTypesExecutor = () => ApiClient.getPolicyTypes();

export const getDropoutsAction = createAction(ActionTypePrefixes.DROPOUTS, getDroputsExecutor);

export const addDropoutAction = createAction(ActionTypePrefixes.ADD_DROPOUT, addDropoutExecutor);

export const editDropoutAction = createAction(ActionTypePrefixes.EDIT_DROPOUT, editDropoutExecutor);

export const deleteDropoutAction = createAction(
  ActionTypePrefixes.DELETE_DROPOUT,
  deleteDropoutExecutor,
  deleteMetaCreator
);

export const getPolicyTypesAction = createAction(ActionTypePrefixes.POLICY_TYPES, getPolicyTypesExecutor);

export type ActionTypes =
  | Action<ActionTypeKeys.DROPOUTS_PENDING, undefined>
  | Action<ActionTypeKeys.DROPOUTS_FULFILLED, DropoutPayload>
  | Action<ActionTypeKeys.DROPOUTS_REJECTED, DropoutPayload>
  | Action<ActionTypeKeys.ADD_DROPOUT_PENDING, undefined>
  | Action<ActionTypeKeys.ADD_DROPOUT_FULFILLED, DropoutPayload>
  | Action<ActionTypeKeys.ADD_DROPOUT_REJECTED, DropoutPayload>
  | Action<ActionTypeKeys.EDIT_DROPOUT_PENDING, undefined>
  | Action<ActionTypeKeys.EDIT_DROPOUT_FULFILLED, EditDropoutPayload>
  | Action<ActionTypeKeys.EDIT_DROPOUT_REJECTED, EditDropoutPayload>
  | Action<ActionTypeKeys.DELETE_DROPOUT_PENDING, undefined, DeleteDropoutMeta>
  | Action<ActionTypeKeys.DELETE_DROPOUT_FULFILLED, string, DeleteDropoutMeta>
  | Action<ActionTypeKeys.DELETE_DROPOUT_REJECTED, string, DeleteDropoutMeta>
  | Action<ActionTypeKeys.POLICY_TYPES_PENDING, undefined>
  | Action<ActionTypeKeys.POLICY_TYPES_FULFILLED, PolicyTypesPayload>
  | Action<ActionTypeKeys.POLICY_TYPES_REJECTED, PolicyTypesPayload>;
