import { Typography, WithStyles } from "@material-ui/core";
import { Column } from "material-table";
import React, { useEffect, useState } from "react";

import { Table } from "../../components/Table";
import { DamageCostRequest, DamageCostResponse } from "./models";
import { DispatchProps, StateProps } from "./redux";
import { DamageCostsClassKey } from "./styles";

export interface DamageCostsProps {}

interface ColumnsState {
  columns: Column<DamageCostResponse>[];
}

export interface DamageCostsInnerProps
  extends DamageCostsProps,
    StateProps,
    DispatchProps,
    WithStyles<DamageCostsClassKey> {}

export const DamageCostsComponent: React.FC<DamageCostsInnerProps> = ({
  getDamageCosts,
  editDamageCosts,
  addDamageCosts,
  deleteDamageCosts,
  damageCosts,
  isLoading,
  classes,
}) => {
  const [columnsState] = useState<ColumnsState>({
    columns: [
      { field: "objectNaam", title: "Name", filtering: true },
      { field: "objectSubnaam", title: "Category", filtering: true },
      { field: "objectSoort", title: "Subcategory", filtering: true },
      { field: "eenheid", title: "Unit", filtering: false },
      { field: "prijsPerEenheid", title: "EUR / unit", filtering: false },
      { field: "uitgangspunt", title: "Star Pt.", filtering: false },
      { field: "voorrijkosten", title: "Call out costs", filtering: false },
      { field: "btwArbeid", title: "VAT Work", filtering: false },
      { field: "btwMateriaal", title: "VAT Material", filtering: false },
      { field: "maxHerstelZelf", title: "Max Rec. Self", filtering: false },
      {
        field: "maxHerstelNietPartner",
        title: "Max Rec. Not Part.",
        filtering: false,
      },
      {
        field: "normHerstelPartner",
        title: "Max Rec. Part.",
        filtering: false,
      },
    ],
  });

  useEffect(() => {
    getDamageCosts();
  }, [getDamageCosts]);

  const handleAddDamageCost = (damageCostShouldBeAdded: DamageCostResponse) => {
    const damageCostToAdd: DamageCostRequest = {
      objectNaam: damageCostShouldBeAdded.objectNaam,
      objectSubnaam: damageCostShouldBeAdded.objectSubnaam,
      objectSoort: damageCostShouldBeAdded.objectSoort,
      eenheid: damageCostShouldBeAdded.eenheid,
      prijsPerEenheid: damageCostShouldBeAdded.prijsPerEenheid,
      uitgangspunt: damageCostShouldBeAdded.uitgangspunt,
      voorrijkosten: 0,
      btwArbeid: 0,
      btwMateriaal: 0,
      maxHerstelZelf: damageCostShouldBeAdded.maxHerstelZelf,
      maxHerstelNietPartner: damageCostShouldBeAdded.maxHerstelNietPartner,
      normHerstelPartner: damageCostShouldBeAdded.normHerstelPartner,
    };

    return addDamageCosts(damageCostToAdd);
  };

  const handleEditDamageCost = (damageCostNewData: DamageCostResponse, damageCostOldData: DamageCostResponse) => {
    const id = damageCostNewData.id;
    const editedDamageCost: DamageCostRequest = {
      objectNaam: damageCostNewData.objectNaam,
      objectSubnaam: damageCostNewData.objectSubnaam,
      objectSoort: damageCostNewData.objectSoort,
      eenheid: damageCostNewData.eenheid,
      prijsPerEenheid: damageCostNewData.prijsPerEenheid,
      uitgangspunt: damageCostNewData.uitgangspunt,
      voorrijkosten: 0,
      btwArbeid: 0,
      btwMateriaal: 0,
      maxHerstelZelf: damageCostNewData.maxHerstelZelf,
      maxHerstelNietPartner: damageCostNewData.maxHerstelNietPartner,
      normHerstelPartner: damageCostNewData.normHerstelPartner,
    };
    return editDamageCosts(id, editedDamageCost);
  };

  const handleDeleteDamageCost = (damageCostShouldBeDeleted: DamageCostResponse) => {
    const deletedDamageCostId: string = damageCostShouldBeDeleted.id.toString();
    return deleteDamageCosts(deletedDamageCostId);
  };

  return (
    <>
      <Typography className={classes.pageTitle} gutterBottom align="center" variant="h4" component="h5">
        Repair Costs
      </Typography>
      <Table
        hasEdit={true}
        hasDelete={false}
        hasExportButton={true}
        handleAddRow={handleAddDamageCost}
        handleEditRow={handleEditDamageCost}
        handleDeleteRow={handleDeleteDamageCost}
        dataTable={damageCosts}
        columnsData={columnsState.columns}
        isLoading={isLoading}
      />
    </>
  );
};
