import { createStyles } from "@material-ui/core";

export type ImportPageClassKey = "pageTitle" | "button";

export const ImportPageStyleRules = createStyles({
  pageTitle: {
    marginTop: "40px",
  },
  button: {
    color: "#fff",
    backgroundColor: "#18BC9C",
    border: "none",
    padding: "10px",
    width: "100%",
    marginTop: "30px",
    cursor: "pointer",
    borderRadius: "4px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#149a80",
    },
  },
});
