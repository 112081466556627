import { Grid } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import logo from "../../images/ems-logo.png";
import { AmountLimit } from "../../pages/AmountLimit";
import { Countries } from "../../pages/Countries";
import { DamageCosts } from "../../pages/DamageCosts";
import { Dropouts } from "../../pages/Dropouts";
import { EditConfigKeysPage } from "../../pages/EditConfigKeysPage";
import { Home } from "../../pages/Homepage";
import { ImportPage } from "../../pages/ImportPage";
import { ImportTranslations } from "../../pages/ImportTranslations";
import { Insured } from "../../pages/Insured";
import { Insurer } from "../../pages/Insured/components/Insurer";
import { Login } from "../../pages/Login";
import { loginSelector } from "../../pages/Login/selectors";
import { Offices } from "../../pages/Offices";
import { PolicyDB } from "../../pages/PolicyDB";
import { PolicyFiles } from "../../pages/PolicyFiles";
import { PolicyMappings } from "../../pages/PolicyMappings";
import { ProposalPercentage } from "../../pages/ProposalPercentage";
import { ServiceKeys } from "../../pages/ServiceKeys";
import { Translations } from "../../pages/Translations";
import { Users } from "../../pages/Users";
import { ErrorBoundary } from "../ErrorBoundary";
import { LoggedOutMenu } from "./components/LoggedOutMenu";
import { ProtectedMenu } from "./components/ProtectedMenu";
import useStyles from "./styles";

export const MenuComponent: React.FC<{}> = () => {
  const classes = useStyles();
  const { isAuthenticated } = useSelector(loginSelector);

  return (
    <Router>
      <nav className={classes.nav}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <img src={logo} height="65" alt="logo" />
          </Grid>
          <Grid item xs={10}>
            <div className={classes.holderLinks}>{isAuthenticated ? <ProtectedMenu /> : <LoggedOutMenu />}</div>
          </Grid>
        </Grid>
      </nav>

      <Switch>
        <Route exact path="/" key="home">
          <ErrorBoundary>
            <Home />
          </ErrorBoundary>
        </Route>
        <Route path="/login" key="login">
          <ErrorBoundary>
            <Login />
          </ErrorBoundary>
        </Route>
        <Route path="/users" key="users">
          <ErrorBoundary>
            <Users />
          </ErrorBoundary>
        </Route>
        <Route path="/config" key="config">
          <ErrorBoundary>
            <EditConfigKeysPage />
          </ErrorBoundary>
        </Route>
        <Route path="/auto-insurers" key="autoInsurers">
          <ErrorBoundary>
            <ImportPage pageTitle="Import Auto Insurers Data" uploadURL="auto-insurers" />
          </ErrorBoundary>
        </Route>
        <Route path="/bonus-malus" key="bonusMalus">
          <ErrorBoundary>
            <ImportPage pageTitle="Import Bonus Malus Data (street/zip)" uploadURL="bonus-malus" />
          </ErrorBoundary>
        </Route>
        <Route path="/coverages" key="coverages">
          <ErrorBoundary>
            <ImportPage pageTitle="Import Coverages Data" uploadURL="coverage" />
          </ErrorBoundary>
        </Route>
        <Route path="/depreciation-list" key="depreciationList">
          <ErrorBoundary>
            <ImportPage pageTitle="Import Depreciation List" uploadURL="depreciation-list" />
          </ErrorBoundary>
        </Route>
        <Route path="/condition-code-filters" key="excludedConditions">
          <ErrorBoundary>
            <ImportPage pageTitle="Import Excluded Conditions" uploadURL="condition-code-filters" />
          </ErrorBoundary>
        </Route>
        <Route path="/geolocation" key="geolocation">
          <ErrorBoundary>
            <ImportPage pageTitle="Import Geolocation Data" uploadURL="geolocation" />
          </ErrorBoundary>
        </Route>
        <Route path="/import-translations" key="importTranslations">
          <ErrorBoundary>
            <ImportTranslations />
          </ErrorBoundary>
        </Route>
        <Route path="/insurer-list" key="insurers">
          <ErrorBoundary>
            <ImportPage pageTitle="Import Insurer List" uploadURL="insurer-list" />
          </ErrorBoundary>
        </Route>
        <Route path="/import-repair-costs" key="importRepairCosts">
          <ErrorBoundary>
            <ImportPage pageTitle="Import Repair Costs Data" uploadURL="repair-costs" />
          </ErrorBoundary>
        </Route>
        <Route path="/amount-limit" key="amountLimit">
          <ErrorBoundary>
            <AmountLimit />
          </ErrorBoundary>
        </Route>
        <Route path="/countries" key="countries">
          <ErrorBoundary>
            <Countries />
          </ErrorBoundary>
        </Route>
        <Route path="/damage-costs" key="damageCosts">
          <ErrorBoundary>
            <DamageCosts />
          </ErrorBoundary>
        </Route>
        <Route path="/dropouts" key="dropouts">
          <ErrorBoundary>
            <Dropouts />
          </ErrorBoundary>
        </Route>
        <Route path="/insured" key="insured">
          <ErrorBoundary>
            <Insured />
          </ErrorBoundary>
        </Route>
        <Route path="/insurer/:id" key="insurer">
          <ErrorBoundary>
            <Insurer />
          </ErrorBoundary>
        </Route>
        <Route path="/offices" key="offices">
          <ErrorBoundary>
            <Offices />
          </ErrorBoundary>
        </Route>
        <Route path="/policy-check/mappings" key="policyMappings">
          <ErrorBoundary>
            <PolicyMappings />
          </ErrorBoundary>
        </Route>
        <Route path="/policy-check/db" key="policydb">
          <ErrorBoundary>
            <PolicyDB />
          </ErrorBoundary>
        </Route>
        <Route path="/policy-check/files" key="policyfiles">
          <ErrorBoundary>
            <PolicyFiles />
          </ErrorBoundary>
        </Route>
        <Route path="/service-keys" key="serviceKeys">
          <ErrorBoundary>
            <ServiceKeys />
          </ErrorBoundary>
        </Route>
        <Route path="/percentages" key="proposalPercentages">
          <ErrorBoundary>
            <ProposalPercentage />
          </ErrorBoundary>
        </Route>
        <Route path="/translations" key="translations">
          <ErrorBoundary>
            <Translations />
          </ErrorBoundary>
        </Route>
      </Switch>
    </Router>
  );
};
