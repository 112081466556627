import { createAction } from "redux-actions";

import ApiClient from "../../services/ApiClient/api-client-services";
import { Action } from "../../types/redux";

interface UploadPayload {
  data: {
    message: string;
  };
}

export enum ActionTypePrefixes {
  UPLOAD = "UPLOAD",
}

export enum ActionTypeKeys {
  RESET_STATE = "RESET_STATE",
  UPLOAD_PENDING = "UPLOAD_PENDING",
  UPLOAD_FULFILLED = "UPLOAD_FULFILLED",
  UPLOAD_REJECTED = "UPLOAD_REJECTED",
}

const uploadFileExecutor = (file: File, url: string) => ApiClient.uploadSingleFile(file, url);

export const uploadFileAction = createAction(ActionTypePrefixes.UPLOAD, uploadFileExecutor);

export const resetStateAction = createAction(ActionTypeKeys.RESET_STATE);

export type ActionTypes =
  | Action<ActionTypeKeys.RESET_STATE, undefined>
  | Action<ActionTypeKeys.UPLOAD_PENDING, undefined>
  | Action<ActionTypeKeys.UPLOAD_FULFILLED, UploadPayload>
  | Action<ActionTypeKeys.UPLOAD_REJECTED, UploadPayload>;
