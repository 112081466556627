import { Typography, WithStyles } from "@material-ui/core";
import { Column } from "material-table";
import React, { useEffect, useState } from "react";

import { Table } from "../../components/Table";
import { OfficeRequest, OfficeResponse } from "./models";
import { DispatchProps, StateProps } from "./redux";
import { OfficesClassKey } from "./styles";

export interface OfficesProps {}

export interface OfficesInnerProps extends OfficesProps, StateProps, DispatchProps, WithStyles<OfficesClassKey> {}

interface ColumnsState {
  columns: Column<OfficeResponse>[];
}

export const OfficesComponent: React.FC<OfficesInnerProps> = ({
  getOffices,
  addOffice,
  editOffice,
  deleteOffice,
  offices,
  isLoading,
  classes,
}) => {
  const [columnsState] = useState<ColumnsState>({
    columns: [
      { field: "id", title: "ID", editable: "never", filtering: false },
      { field: "name", title: "Name", filtering: true },
      {
        field: "layoutKey",
        title: "Layout Key",
        filtering: true,
      },
      { field: "code", title: "Code", filtering: true },
      { field: "email", title: "Email", filtering: true },
      {
        field: "phone",
        title: "Phone",
        filtering: true,
      },
    ],
  });

  useEffect(() => {
    getOffices();
  }, [getOffices]);

  const handleAddOffice = (officeShouldBeAdded: OfficeResponse) => {
    const officeToAdd: OfficeRequest = {
      name: officeShouldBeAdded.name,
      layoutKey: officeShouldBeAdded.layoutKey,
      code: officeShouldBeAdded.code,
      email: officeShouldBeAdded.email,
      phone: officeShouldBeAdded.phone,
    };
    return addOffice(officeToAdd);
  };

  const handleEditOffice = (officeNewData: OfficeResponse, officeOldData: OfficeResponse) => {
    const editedOffice: OfficeRequest = {
      name: officeNewData.name,
      layoutKey: officeNewData.layoutKey,
      code: officeNewData.code,
      email: officeNewData.email,
      phone: officeNewData.phone,
    };

    return editOffice(officeNewData.id, editedOffice);
  };

  const handleDeleteOffice = (officeShouldBeDeleted: OfficeResponse) => {
    const deletedOfficeId: number = officeShouldBeDeleted.id;

    return deleteOffice(deletedOfficeId);
  };

  return (
    <>
      <Typography className={classes.pageTitle} gutterBottom align="center" variant="h4" component="h5">
        Offices
      </Typography>
      {offices && (
        <Table
          hasEdit={true}
          hasDelete={true}
          handleAddRow={handleAddOffice}
          handleEditRow={handleEditOffice}
          handleDeleteRow={handleDeleteOffice}
          dataTable={offices}
          columnsData={columnsState.columns}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
