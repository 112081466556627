import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "recompose";
import { createSelector } from "reselect";

import { protectedComponentHoc } from "../../components/ProtectedComponent";
import { OfficesComponent, OfficesInnerProps, OfficesProps } from "./component";
import { mapDispatchToProps, mapStateToProps } from "./redux";
import { officesSelector } from "./selectors";
import { OfficesStyleRules } from "./styles";

export const Offices = compose<OfficesInnerProps, OfficesProps>(
  connect(createSelector(officesSelector, mapStateToProps), mapDispatchToProps),
  withStyles(OfficesStyleRules),
  protectedComponentHoc
)(OfficesComponent);
