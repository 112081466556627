import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  divider: {
    marginTop: "20px",
    marginBottom: "20px",
  },
});

export default useStyles;
