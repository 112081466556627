import { ActionTypeKeys, ActionTypes } from "./actions";

export interface ImportPageState {
  importPage: {
    message: string;
  };
  pendingUpload: boolean;
}

export const uploadInitialState: ImportPageState = {
  importPage: {
    message: "",
  },
  pendingUpload: false,
};

export const importPageReducer = (state: ImportPageState = uploadInitialState, action: ActionTypes) => {
  switch (action.type) {
    case ActionTypeKeys.RESET_STATE: {
      return uploadInitialState;
    }
    case ActionTypeKeys.UPLOAD_FULFILLED: {
      return {
        ...state,
        importPage: action.payload.data || uploadInitialState.importPage,
        pendingUpload: false,
      };
    }
    case ActionTypeKeys.UPLOAD_PENDING: {
      return {
        ...state,
        pendingUpload: true,
      };
    }
    case ActionTypeKeys.UPLOAD_REJECTED: {
      return {
        ...state,
        pendingUpload: false,
      };
    }
    default:
      return state;
  }
};
