import { MenuItem, Select, Typography } from "@material-ui/core";
import { Column } from "material-table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Table } from "../../components/Table";
import ApiClient from "../../services/ApiClient/api-client-services";
import { addCountry, deleteCountry, fetchCountries, updateCountry } from "./countriesSlice";
import { Country, CountryResponse, Insurer } from "./models";
import { countriesSelector } from "./selectors";
import useStyles from "./styles";

export interface CountriesProps {}

interface ColumnsState {
  columns: Column<CountryResponse>[];
}

export const CountriesComponent: React.FC<any> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { countries, isLoading } = useSelector(countriesSelector);
  const [insurer, setInsurer] = React.useState("1");
  const [insurers, setInsurers] = React.useState<Insurer[]>([]);

  const [columnsState] = useState<ColumnsState>({
    columns: [
      { field: "name", title: "Of. Name", filtering: false },
      { field: "officialName", title: "Name", filtering: true },
      { field: "alpha2", title: "Code", filtering: false },
      { field: "alpha3", title: "ISO Code", filtering: false },
      { field: "currencyCode", title: "Currency", filtering: false },
      {
        field: "isEuropean",
        title: "European",
        type: "boolean",
        filtering: false,
      },
      {
        field: "isGreencard",
        title: "Greencard",
        type: "boolean",
        filtering: false,
      },
      {
        field: "isExcluded",
        title: "Excluded",
        type: "boolean",
        filtering: false,
      },
    ],
  });

  useEffect(() => {
    ApiClient.loadInsurers().then(({ data }) => {
      if (Array.isArray(data)) {
        setInsurers(data);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(fetchCountries(insurer));
  }, [dispatch, insurer]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setInsurer(event.target.value as string);
  };

  const insurersSelect = insurers.length > 0 && (
    <Select className={classes.select} value={insurer} onChange={handleChange}>
      {insurers.map((insurer) => (
        <MenuItem key={insurer.id} value={insurer.id}>
          {insurer.name}
        </MenuItem>
      ))}
    </Select>
  );

  const handleAddCountry = async (country: Country) => {
    dispatch(
      addCountry({
        insurerId: insurer,
        country,
      })
    );
  };

  const handleEditCountry = async (countryNewData: CountryResponse) => {
    const { id, ...country } = countryNewData;
    dispatch(
      updateCountry({
        insurerId: insurer,
        id,
        country,
      })
    );
  };

  const handleDeleteCountry = async (country: CountryResponse) => {
    dispatch(
      deleteCountry({
        insurerId: insurer,
        id: country.id,
      })
    );
  };

  return (
    <>
      <Typography className={classes.pageTitle} gutterBottom align="center" variant="h4" component="h5">
        Countries
      </Typography>
      {insurersSelect}
      <Table
        hasEdit={true}
        hasDelete={true}
        hasExportButton={true}
        handleAddRow={handleAddCountry}
        handleEditRow={handleEditCountry}
        handleDeleteRow={handleDeleteCountry}
        dataTable={countries}
        columnsData={columnsState.columns}
        isLoading={isLoading}
      />
    </>
  );
};
