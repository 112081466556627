import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import useAxios from "axios-hooks";
import * as React from "react";
import { useDispatch } from "react-redux";

import { LANGUAGES_URL } from "../../../services/ApiClient/urls";
import { fetchLanguages } from "../languagesSlice";
import useStyles from "../styles";

const countryCodePattern =
  "^$|^(aa|ab|ae|af|ak|am|an|ar|as|av|ay|az|az|ba|be|bg|bh|bi|bm|bn|bo|br|bs|ca|ce|ch|co|cr|cs|cu|cv|cy|da|de|dv|dz|ee|el|en|eo|es|et|eu|fa|ff|fi|fj|fo|fr|fy|ga|gd|gl|gn|gu|gv|ha|he|hi|ho|hr|ht|hu|hy|hz|ia|id|ie|ig|ii|ik|io|is|it|iu|ja|jv|ka|kg|ki|kj|kk|kl|km|kn|ko|kr|ks|ku|kv|kw|ky|la|lb|lg|li|ln|lo|lt|lu|lv|mg|mh|mi|mk|ml|mn|mr|ms|mt|my|na|nb|nd|ne|ng|nl|nn|no|nr|nv|ny|oc|oj|om|or|os|pa|pi|pl|ps|pt|qu|rm|rn|ro|ru|rw|sa|sc|sd|se|sg|si|sk|sl|sm|sn|so|sq|sr|ss|st|su|sv|sw|ta|te|tg|th|ti|tk|tl|tn|to|tr|ts|tt|tw|ty|ug|uk|ur|uz|ve|vi|vo|wa|wo|xh|yi|yo|za|zh|zu)$";
const regexPattern = new RegExp(countryCodePattern, "i");

export const AddTranslationLanguage: React.FC<{}> = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [codeError, setCodeError] = React.useState<string | undefined>(undefined);
  const [name, setName] = React.useState("");
  const [{ loading, error }, createLanguage] = useAxios(
    {
      url: LANGUAGES_URL,
      method: "POST",
    },
    {
      manual: true,
    }
  );
  const [successMessage, setSuccessMessage] = React.useState("");

  const dispatch = useDispatch();

  const handleClick = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  const saveLanguage = () => {
    if (code === "" || validateKey(code, regexPattern) !== undefined) return;

    setSuccessMessage("");
    const lowerCode = code.toLowerCase();

    createLanguage({
      data: { code: lowerCode, name },
    }).then(({ data: { msg } }) => {
      if (msg === "ok") {
        setSuccessMessage(`${lowerCode} was added.`);
        dispatch(fetchLanguages());
        setCode("");
        setName("");
        closeDialog();
      }
    });
  };

  const validateKey = (key: string, pattern: RegExp) => {
    const isValid = pattern.test(key);

    return isValid ? undefined : "Must be an ISO-639-1 language code";
  };

  React.useEffect(() => {
    setCodeError(validateKey(code, regexPattern));
  }, [code]);

  return (
    <>
      <Button onClick={handleClick} size="small">
        Add Language
      </Button>
      <Dialog onClose={closeDialog} aria-labelledby="add-language-title" open={isOpen}>
        <DialogTitle id="add-language-title">Add a new language</DialogTitle>
        <DialogContent>
          <form
            autoComplete="off"
            className={classes.form}
            onSubmit={(e) => {
              e.preventDefault();
              saveLanguage();
            }}
          >
            <TextField
              id="language-code"
              label="Code"
              margin="normal"
              fullWidth={true}
              error={!!codeError}
              helperText={codeError}
              value={code}
              inputProps={{
                pattern: countryCodePattern,
              }}
              onChange={(event) => {
                setCode(event.target.value.toLowerCase());
              }}
            />
            <TextField
              id="language-name"
              label="Name"
              helperText="optional"
              margin="normal"
              fullWidth={true}
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </form>
          {loading && <CircularProgress />}
          {error && <Alert severity="error">{error.response?.data || error.message}</Alert>}
          {successMessage && <Alert>{successMessage}</Alert>}
        </DialogContent>
      </Dialog>
    </>
  );
};
