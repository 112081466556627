import { Dispatch } from "redux";

import { editPercentageAction, getPolicyTypesAction } from "../../data/proposalPercentages";
import { AsyncDispatch } from "../../types/redux";
import { Policy } from "../Dropouts/models";
import { PercentagesState } from "./reducer";

export interface DispatchProps {
  getPolicyTypes: () => void;
  editPercentage: (percentage: Policy) => Promise<AsyncDispatch>;
}

export interface StateProps extends PercentagesState {}

export const mapStateToProps = (percentagesState: PercentagesState): StateProps => {
  return {
    percentages: percentagesState.percentages,
    isLoading: percentagesState.isLoading,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  getPolicyTypes: () => dispatch(getPolicyTypesAction()),
  editPercentage: (percentage: Policy) => dispatch(editPercentageAction(percentage)),
});
