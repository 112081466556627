import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  pageTitle: {
    marginTop: "40px",
  },
  link: {
    color: "#18BC9C",
    textDecoration: "none",
  },
});

export default useStyles;
