import { Typography } from "@material-ui/core";
import { Column } from "material-table";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { Table } from "../../components/Table";
import { showErrorAction } from "../../components/Toast/toastSlice";
import { AppState } from "../../reducers";
import { useAppDispatch } from "../../redux/use-app-dispatch";
import { LookupItem } from "../Users/models";
import { addConfigKey, editConfigKey, getAllOffices, getConfigKeys, removeConfigKey } from "./actions";
import { dataTypeErrorMessage, dataTypeItems, emptyFieldsErrorMessage } from "./constants";
import { ConfigKey, ConfigKeyInput } from "./types";

type ColumnsState = {
  columns: Column<any>[];
};

export const EditConfigKeysPage = () => {
  const dispatch = useAppDispatch();

  const {
    configKeys: { configKeys, loading, offices, nullOfficeId },
  } = useSelector((state: AppState) => state);

  useEffect(() => {
    dispatch(getConfigKeys()).then(() => {
      dispatch(getAllOffices());
    });
  }, []);

  const officesLookup: LookupItem = offices.reduce((acc, e) => {
    return {
      [e.id]: e.name,
      ...acc,
    };
  }, {});

  const columnsWithRolesLookup: ColumnsState = {
    columns: [
      { field: "id", title: "ID", editable: "never", filtering: false },
      { field: "name", title: "Name", filtering: true },
      { field: "description", title: "Description", filtering: true },
      { field: "key", title: "Key", filtering: true },
      {
        field: "datatype",
        title: "Data Type",
        lookup: dataTypeItems,
        render: (rowData: ConfigKey) => {
          return (
            <Typography key={1} gutterBottom variant="inherit" component="p">
              {dataTypeItems[rowData.datatype]}
            </Typography>
          );
        },
      },
      { field: "value", title: "Value", filtering: true },
      {
        field: "offices",
        title: "Offices",
        lookup: officesLookup,
        render: (rowData: ConfigKey) => {
          return (
            <Typography key={1} gutterBottom variant="inherit" component="p">
              {officesLookup[rowData.office ? rowData.office : 0]}
            </Typography>
          );
        },

        customFilterAndSearch: (terms: string[], rowData: ConfigKey) => {
          if (terms.length > 0) {
            const ids = terms.map((term) => parseInt(term));
            const userShouldRemain = ids.includes(rowData.office);
            return userShouldRemain;
          }

          return true;
        },
      },
    ],
  };

  const handleAddKey = (newData: ConfigKeyInput) => {
    const officeValue =
      newData.offices && parseInt(newData.offices) !== nullOfficeId ? parseInt(newData.offices) : null;

    const payload = {
      key: newData.key,
      datatype: newData.datatype,
      name: newData.name && newData.name,
      value: newData.value,
      description: newData.description,
      office: officeValue,
    };

    if (payload.description === "" || payload.key === "" || payload.name === "" || payload.value === "") {
      dispatch(showErrorAction(emptyFieldsErrorMessage));

      return Promise.resolve();
    }

    if (newData.datatype !== "int" && newData.datatype !== "bool" && newData.datatype !== "string") {
      dispatch(showErrorAction(dataTypeErrorMessage));

      return Promise.resolve();
    }

    return new Promise((resolve) => {
      resolve(dispatch(addConfigKey(payload)).then(() => dispatch(getConfigKeys())));
    });
  };

  const handleEditKey = (newData: ConfigKey) => {
    const officeValue = parseInt(newData.offices) !== nullOfficeId ? parseInt(newData.offices) : null;

    const payload = {
      id: newData.id,
      data: {
        key: newData.key ?? "",
        datatype: newData.datatype ?? "",
        name: newData.name ?? "",
        value: newData.value ?? "",
        description: newData.description ?? "",
        office: officeValue,
      },
    };

    if (
      payload.data.description === "" ||
      payload.data.key === "" ||
      payload.data.name === "" ||
      payload.data.value === ""
    ) {
      dispatch(showErrorAction(emptyFieldsErrorMessage));

      return Promise.resolve();
    }

    if (newData.datatype !== "int" && newData.datatype !== "bool" && newData.datatype !== "string") {
      dispatch(showErrorAction(dataTypeErrorMessage));

      return Promise.resolve();
    }

    return new Promise((resolve) => {
      resolve(dispatch(editConfigKey(payload)).then(() => dispatch(getConfigKeys())));
    });
  };

  const handleDeleteKey = (data: ConfigKey) => {
    return new Promise((resolve) => {
      resolve(dispatch(removeConfigKey(data.id)).then(() => dispatch(getConfigKeys())));
    });
  };

  return (
    <>
      <Typography style={{ marginTop: 50 }} gutterBottom align="center" variant="h4" component="h5">
        <a
          href={
            "https://emsclaimsengine.atlassian.net/wiki/spaces/PRAC/pages/23003141/Configuration+Key+Management+-+in+progress"
          }
          rel={"noopener noreferrer"}
          target="_blank"
        >
          Edit Configuration Keys Page
        </a>
      </Typography>
      {columnsWithRolesLookup && (
        <Table
          hasEdit={true}
          hasDelete={true}
          handleAddRow={handleAddKey}
          handleEditRow={handleEditKey}
          handleDeleteRow={handleDeleteKey}
          dataTable={configKeys}
          columnsData={columnsWithRolesLookup.columns}
          isLoading={loading}
        />
      )}
    </>
  );
};
