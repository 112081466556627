import axios from "axios";

import { AppState } from "../../reducers";
import { AppDispatch } from "../../redux";
import { createBaseUrl, createConfigHeaders } from "../EditConfigKeysPage/api-functions";
import { ActionTypeKeys } from "./actions";
import {
  HERSTEL_PARTNERS_URL_EXTENSION,
  HERSTEL_PARTNERS_URL_LOCAL,
  MAKELAARS_URL_EXTENSION,
  MAKELAARS_URL_LOCAL,
} from "./constants";

const MAKELAARS_URL = createBaseUrl(MAKELAARS_URL_EXTENSION) ?? MAKELAARS_URL_LOCAL;
const HERSTEL_PARTNERS_URL = createBaseUrl(HERSTEL_PARTNERS_URL_EXTENSION) ?? HERSTEL_PARTNERS_URL_LOCAL;

export const getAllMakelaars = () => (dispatch: AppDispatch<any>, getState: () => AppState) => {
  dispatch({ type: ActionTypeKeys.MAKELAARS_PENDING });

  const {
    login: { token },
  } = getState();

  const config = createConfigHeaders(token);

  return new Promise((resolve, reject) => {
    axios
      .get(`${MAKELAARS_URL}`, config)
      .then((res) => {
        dispatch({ type: ActionTypeKeys.MAKELAARS_FULFILLED, payload: res.data });
        resolve(res.data);
      })
      .catch((err) => {
        dispatch({ type: ActionTypeKeys.MAKELAARS_REJECTED });
        reject(err);
      });
  });
};

export const getAllHerstelPartners = () => (dispatch: AppDispatch<any>, getState: () => AppState) => {
  dispatch({ type: ActionTypeKeys.HERSTELERS_PENDING });

  const {
    login: { token },
  } = getState();

  const config = createConfigHeaders(token);

  return new Promise((resolve, reject) => {
    axios
      .get(`${HERSTEL_PARTNERS_URL}`, config)
      .then((res) => {
        dispatch({ type: ActionTypeKeys.HERSTELERS_FULFILLED, payload: res.data });
        resolve(res.data);
      })
      .catch((err) => {
        dispatch({ type: ActionTypeKeys.HERSTELERS_REJECTED });
        reject(err);
      });
  });
};
