import { ActionTypeKeysPercentage, ActionTypesPercentage } from "../../data/proposalPercentages";
import { Policy } from "../Dropouts/models";

export interface PercentagesState {
  isLoading: boolean;
  percentages: Policy[];
}

export const percentagesInitialState: PercentagesState = {
  isLoading: false,
  percentages: [],
};

const editPolicy = (policies: Policy[], updatedPolicy: Policy) =>
  policies.map((policy: Policy) => {
    policy.percentage = policy.name === updatedPolicy.name ? updatedPolicy.percentage : policy.percentage;

    return policy;
  });

export const percentagesReducer = (
  state: PercentagesState = percentagesInitialState,
  action: ActionTypesPercentage
) => {
  switch (action.type) {
    case ActionTypeKeysPercentage.EDIT_PERCENTAGE_PENDING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ActionTypeKeysPercentage.EDIT_PERCENTAGE_FULFILLED: {
      return {
        ...state,
        percentages: editPolicy(state.percentages, action.payload.data),
        isLoading: false,
      };
    }
    case ActionTypeKeysPercentage.EDIT_PERCENTAGE_REJECTED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ActionTypeKeysPercentage.PROPOSAL_POLICY_TYPES_FULFILLED: {
      return {
        ...state,
        isLoading: false,
        percentages: Array.isArray(action.payload.data) ? action.payload.data : state.percentages,
      };
    }
    case ActionTypeKeysPercentage.PROPOSAL_POLICY_TYPES_PENDING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ActionTypeKeysPercentage.PROPOSAL_POLICY_TYPES_REJECTED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
