import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "recompose";
import { createSelector } from "reselect";

import { protectedComponentHoc } from "../../components/ProtectedComponent";
import { UsersComponent, UsersInnerProps, UsersProps } from "./component";
import { mapDispatchToProps, mapStateToProps } from "./redux";
import { usersSelector } from "./selectors";
import { UsersStyleRules } from "./styles";

export const Users = compose<UsersInnerProps, UsersProps>(
  connect(createSelector(usersSelector, mapStateToProps), mapDispatchToProps),
  withStyles(UsersStyleRules),
  protectedComponentHoc
)(UsersComponent);
