import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import useAxios from "axios-hooks";
import * as React from "react";

import { CUSTOMERS_URL } from "../../../services/ApiClient/urls";
import useStyles from "../styles";

const pattern = "^$|(^[a-zA-Z_][a-zA-Z0-9_]*)$";
const regexPattern = new RegExp(pattern);

export const AddTranslationCustomer: React.FC<any> = (props) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [codeError, setCodeError] = React.useState<string | undefined>(undefined);
  const [name, setName] = React.useState("");
  const [{ loading, error }, createCustomer] = useAxios(
    {
      url: CUSTOMERS_URL,
      method: "POST",
    },
    {
      manual: true,
    }
  );
  const [successMessage, setSuccessMessage] = React.useState("");

  const handleClick = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
    setSuccessMessage("");
    setCode("");
    setName("");
  };

  const saveCustomer = () => {
    if (code === "" || validateKey(code, regexPattern) !== undefined) return;

    setSuccessMessage("");
    const lowerCode = code.toLowerCase();

    createCustomer({
      data: { code: lowerCode, name },
    }).then(({ data: { msg } }) => {
      if (msg === "ok") {
        setSuccessMessage(`${lowerCode} was added.`);
        setCode("");
        setName("");
        props.onSave();
        closeDialog();
      }
    });
  };

  const validateKey = (key: string, pattern: RegExp) => {
    const isValid = pattern.test(key);

    return isValid ? undefined : "May contain letters, numbers (but not start with one) and underscores only.";
  };

  React.useEffect(() => {
    setCodeError(validateKey(code, regexPattern));
  }, [code]);

  return (
    <>
      <Button onClick={handleClick} size="small">
        Add Customer
      </Button>
      <Dialog onClose={closeDialog} aria-labelledby="add-customer-title" open={isOpen}>
        <DialogTitle id="add-customer-title">Add a new customer</DialogTitle>
        <DialogContent>
          <form
            autoComplete="off"
            className={classes.form}
            onSubmit={(e) => {
              e.preventDefault();
              saveCustomer();
            }}
          >
            <TextField
              id="customer-code"
              label="Code"
              margin="normal"
              fullWidth={true}
              error={!!codeError}
              helperText={codeError}
              value={code}
              inputProps={{
                pattern,
              }}
              onChange={(event) => {
                setCode(event.target.value);
              }}
            />
            <TextField
              id="customer-name"
              label="Name"
              helperText="optional"
              margin="normal"
              fullWidth={true}
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </form>
          {loading && <CircularProgress />}
          {error && <Alert severity="error">{error.response?.data || error.message}</Alert>}
          {successMessage && <Alert>{successMessage}</Alert>}
        </DialogContent>
      </Dialog>
    </>
  );
};
