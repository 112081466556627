import { createSlice } from "@reduxjs/toolkit";

import { addConfigKey, editConfigKey, getAllOffices, getConfigKeys, removeConfigKey } from "./actions";
import { ConfigKeysState } from "./types";

export const initialState: ConfigKeysState = {
  loading: false,
  configKeys: [],
  offices: [],
  nullOfficeId: null,
};

export const configKeysSlice = createSlice({
  name: "configKeys",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAllOffices.fulfilled, (state, action) => {
      const highestId = action.payload.reduce((acc, val) => {
        return Math.max(acc, val.id);
      }, -Infinity);

      const nullValue = {
        code: "00000",
        email: undefined,
        id: highestId + 1,
        layoutKey: "null",
        name: "null",
        phone: undefined,
      };

      state.loading = false;
      state.offices = [...action.payload, nullValue];
      state.nullOfficeId = highestId + 1;
    });
    builder.addCase(getAllOffices.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getAllOffices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getConfigKeys.fulfilled, (state, action) => {
      state.loading = false;
      state.configKeys = action.payload;
    });
    builder.addCase(getConfigKeys.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getConfigKeys.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editConfigKey.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(editConfigKey.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(editConfigKey.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addConfigKey.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(addConfigKey.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addConfigKey.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(removeConfigKey.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(removeConfigKey.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(removeConfigKey.pending, (state) => {
      state.loading = true;
    });
  },
});
