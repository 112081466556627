import { ActionTypeKeys, ActionTypes } from "./actions";
import { DamageCostResponse } from "./models";

export interface DamageCostsState {
  damageCosts: DamageCostResponse[];
  isLoading: boolean;
}

export const damageCostsInitialState: DamageCostsState = {
  damageCosts: [],
  isLoading: false,
};

const editDamageCost = (costs: DamageCostResponse[], requestData: string) => {
  const costEdited = JSON.parse(requestData);
  const id: number = parseInt(Object.keys(costEdited)[0]);

  const index = costs.findIndex((el) => el.id === id);
  if (index === -1) {
    return costs;
  }

  const cost = { id, ...costEdited[id] };
  const updatedCosts = costs.slice();
  updatedCosts[index] = cost;

  return updatedCosts;
};

export const damageCostsReducer = (state: DamageCostsState = damageCostsInitialState, action: ActionTypes) => {
  switch (action.type) {
    case ActionTypeKeys.DAMAGE_COSTS_PENDING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ActionTypeKeys.DAMAGE_COSTS_FULFILLED: {
      return {
        ...state,
        damageCosts: Array.isArray(action.payload.data) ? action.payload.data : state.damageCosts,
        isLoading: false,
      };
    }
    case ActionTypeKeys.ADD_DAMAGE_COSTS_FULFILLED: {
      return {
        ...state,
      };
    }
    case ActionTypeKeys.EDIT_DAMAGE_COSTS_FULFILLED: {
      return {
        ...state,
        damageCosts: editDamageCost(state.damageCosts, action.payload.config.data),
      };
    }
    case ActionTypeKeys.DELETE_DAMAGE_COSTS_FULFILLED: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};
