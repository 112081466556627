import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import MaterialTable, { Column } from "material-table";
import * as React from "react";
import { useSelector } from "react-redux";

import { tableIcons } from "../../../components/tableIcons";
import ApiClient from "../../../services/ApiClient/api-client-services";
import { Customer } from "../../../services/ApiClient/models";
import { Language, TranslationContent, TranslationId, TranslationListResponse } from "../model";
import { languagesSelector } from "../selectors";
import useStyles from "../styles";

const columns: Column<MTableRow>[] = [
  {
    field: "translation_label",
    title: "Label",
    editable: "never",
    width: "30%",
  },
  {
    field: "defaultContent",
    title: "Default",
    editable: "never",
    width: "20%",
  },
  {
    field: "overrideContent",
    title: "Override",
    resizable: true,
    width: "50%",
  },
];

interface MTableRow {
  translation_label_id: number;
  translation_label: string;
  defaultContent: TranslationContent;
  overrideContent: TranslationContent;
  overrideId?: TranslationId;
  tableData?: {
    id: number;
  };
}

const OverrideTranslations: React.FC<any> = (props) => {
  const classes = useStyles();
  const { languageList, defaultLanguage } = useSelector(languagesSelector);
  const [customer, setCustomer] = React.useState<number | "">("");
  const [language, setLanguage] = React.useState<number>(
    props.selectedLang.length > 0 ? props.selectedLang[0] : defaultLanguage
  );
  const { data: rawData, translationLoading, translationError } = props.translationsList;
  const translationRefetch = props.translationRefetch;
  const { customerList, customerError } = props;
  const [data, setData] = React.useState<MTableRow[]>([]);

  const handleSelectedLang = (value: number) => {
    setLanguage(value);
    props.onChange([value]);
  };

  const onChangeCustomer = (customerId: number) => {
    setCustomer(customerId);
    translationRefetch();
  };

  const renderCustomers = (options: Customer[]) =>
    options.map(({ ID, customer_name }) => (
      <MenuItem key={ID} value={ID.toString()}>
        {customer_name}
      </MenuItem>
    ));

  const renderLanguages = (options: Language[]) =>
    options.map(({ id, language_name }) => (
      <MenuItem key={id} value={id.toString()}>
        {language_name}
      </MenuItem>
    ));

  const updateTranslation = (translationId: TranslationId, text: string) =>
    ApiClient.editTranslation(translationId, text);

  const createTranslation = (label_id: number, text: string) =>
    ApiClient.addTranslation({
      label_id,
      language_id: language,
      text,
      customer_id: customer,
    });

  const deleteTranslation = (translationId: TranslationId) => ApiClient.deleteTranslation(translationId);

  const bulkEditRequests = (changes: Record<number, { oldData: MTableRow; newData: MTableRow }>) =>
    Object.values(changes).map(({ newData }) => {
      const { translation_label_id, overrideContent, overrideId } = newData;

      if (overrideId) {
        if (!overrideContent) {
          return deleteTranslation(overrideId);
        }

        return updateTranslation(overrideId, overrideContent);
      }

      return createTranslation(translation_label_id, overrideContent);
    });

  const modelTranslations = (data: TranslationListResponse[], customer: number | ""): MTableRow[] => {
    return data.map((row) => {
      const { translations, ...rest } = row;

      const { translation_content: defaultContent = "" } =
        translations.find((t) => t.translation_customer_id === 0) || {};

      const { translation_content: overrideContent = "", translation_id: overrideId } =
        (customer && translations.find((t) => t.translation_customer_id === customer)) || {};

      return {
        ...rest,
        defaultContent,
        overrideContent,
        overrideId,
      };
    });
  };

  React.useEffect(() => {
    if (Array.isArray(rawData)) {
      setData(modelTranslations(rawData, customer));
    }
  }, [rawData, customer]);

  return (
    <>
      {translationError && translationError.response?.data?.message}
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <FormControl className={classes.formControl} error={customer === ""}>
          <InputLabel id="customer-label">Customer</InputLabel>
          <Select
            labelId="customer-label"
            id="customer"
            value={customer}
            onChange={(event) => {
              onChangeCustomer(parseInt(event.target.value as string));
            }}
          >
            {renderCustomers(customerList)}
          </Select>
          {customer === "" && <FormHelperText>Select a customer</FormHelperText>}
          {customerError && customerError.response?.data}
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="customer-label">Language</InputLabel>
          <Select
            labelId="language-label"
            id="language"
            value={language}
            onChange={(event) => {
              handleSelectedLang(parseInt(event.target.value as string));
            }}
          >
            {renderLanguages(languageList)}
          </Select>
        </FormControl>
      </Box>
      <MaterialTable
        data={data}
        columns={columns}
        icons={tableIcons}
        isLoading={translationLoading}
        actions={[
          ...(!customer
            ? []
            : [
                {
                  icon: () => <span>RESET</span>,
                  tooltip: "Reset all selected overrides to default",
                  onClick: (event: any, data: any) => {
                    (data as MTableRow[]).forEach(({ overrideId }) => {
                      if (overrideId !== undefined) {
                        deleteTranslation(overrideId);
                      }
                    });
                    translationRefetch();
                  },
                },
              ]),
          {
            icon: () => <RefreshIcon />,
            tooltip: "Refresh Data",
            isFreeAction: true,
            onClick: () => {
              translationRefetch();
            },
          },
        ]}
        options={{
          pageSize: 20,
          showTitle: false,
          selection: !!customer,
          actionsColumnIndex: -1,
          tableLayout: "fixed",
        }}
        editable={
          !customer
            ? undefined
            : {
                onBulkUpdate: (changes) =>
                  Promise.all(bulkEditRequests(changes)).then((values) => {
                    const dataUpdate = [...data];
                    Object.values(changes).forEach(({ newData }, key: number) => {
                      const index = newData.tableData && newData.tableData.id;

                      if (index !== undefined) {
                        let overrideId = values[key].data.ID !== undefined ? values[key].data.ID : values[key].data.id;

                        overrideId = newData.overrideContent === "" ? undefined : overrideId;

                        newData.overrideId = overrideId;
                        dataUpdate[index] = newData;
                      }
                    });
                    setData(dataUpdate);
                  }),
              }
        }
      />
    </>
  );
};

export default OverrideTranslations;
