import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ApiClient from "../../services/ApiClient/api-client-services";
import { isPendingAction, isRejectedAction } from "../../utils/typeguards";
import { CountryRequest, CountryResponse, DeleteCountryRequest, UpdateCountryRequest } from "./models";

export interface CountriesState {
  countries: CountryResponse[];
  isLoading: boolean;
}

export const initialState: CountriesState = {
  countries: [],
  isLoading: false,
};

export const fetchCountries = createAsyncThunk("countries/fetch", async (insurerId: string) => {
  const response = await ApiClient.loadCountries(insurerId);

  return (await response.data) as CountryResponse[];
});

export const addCountry = createAsyncThunk("countries/add", async (country: CountryRequest) => {
  const response = await ApiClient.addCountry(country);

  return (await response.data) as string;
});

export const updateCountry = createAsyncThunk("countries/update", async (country: UpdateCountryRequest) => {
  const response = await ApiClient.updateCountry(country);

  return (await response.data) as string;
});

export const deleteCountry = createAsyncThunk("countries/delete", async (country: DeleteCountryRequest) => {
  const response = await ApiClient.deleteCountry(country);

  return (await response.data) as string;
});

export const countriesSlice = createSlice({
  name: "countries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCountries.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      if (!Array.isArray(payload)) {
        return state;
      }

      state.countries = payload;
    });

    builder.addCase(addCountry.fulfilled, (state, { meta, payload }) => {
      state.isLoading = false;

      if (!Array.isArray(payload) || payload.length === 0) {
        return state;
      }

      const { country } = meta.arg;
      state.countries.push({
        id: payload[0],
        ...country,
      });
    });

    builder.addCase(updateCountry.fulfilled, (state, { meta, payload }) => {
      state.isLoading = false;
      if (!Array.isArray(payload)) {
        return state;
      }

      const { id, country } = meta.arg;
      const index = state.countries.findIndex((el) => el.id === id);
      state.countries[index] = { id, ...country };
    });

    builder.addCase(deleteCountry.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (typeof payload !== "number") {
        return state;
      }

      const countries = state.countries.filter((el) => el.id !== payload);
      state.countries = countries;
    });

    builder.addMatcher(isPendingAction, (state) => {
      state.isLoading = true;
    });

    builder.addMatcher(isRejectedAction, (state) => {
      state.isLoading = false;
    });
  },
});

export const { reducer: countriesReducer } = countriesSlice;
