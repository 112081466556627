export const AUTH_URL = "auth";
export const CLEAR_CACHE = "translations/cache";
export const DROPOUTS_URL = "dropout-rule";
export const EXCHANGE_TOKEN = "exchange";
export const USERS_URL = "users";
export const INSURED_URL = "insured";
export const REPAIR_COST_URL = "repair-cost";
export const COUNTRY_BASE_URL = "country";
export const INSURER_BASE_URL = "insurer";
export const OFFICES_BASE_URL = "office";
export const POLICY_TYPES_URL = "policies/types";
export const ROLES_URL = "roles";
export const PUBLIC_KEY_URL = "api-keys";
export const SERVICES_URL = "service";
export const LANGUAGES_URL = "translations/language";
export const CUSTOMERS_URL = "translations/customer";
export const TRANSLATION_LABEL_URL = "translations/label";
export const TRANSLATIONS_LIST = "translations/list";
export const TRANSLATIONS = "translations";
export const UPLOAD_TRANSLATIONS = "upload/translations";
export const PERCENTAGE_UPDATE_URL = "percentage/percentage-proposal";
