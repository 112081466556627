import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  form: {
    "& > *": {
      margin: "16px",
    },
  },
});

export default useStyles;
