import { Dispatch } from "redux";

import { addOfficeAction, deleteOfficeAction, editOfficeAction, getOfficesAction } from "../../data";
import { AsyncDispatch } from "../../types/redux";
import { OfficeRequest } from "./models";
import { OfficesState } from "./reducer";

export interface DispatchProps {
  getOffices: () => void;
  addOffice: (office: OfficeRequest) => Promise<AsyncDispatch>;
  editOffice: (id: number, office: OfficeRequest) => Promise<AsyncDispatch>;
  deleteOffice: (officeId: number) => Promise<AsyncDispatch>;
}

export interface StateProps extends OfficesState {}

export const mapStateToProps = (officesState: OfficesState): StateProps => {
  return {
    offices: officesState.offices,
    isLoading: officesState.isLoading,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  getOffices: () => {
    dispatch(getOfficesAction());
  },
  addOffice: (office) => dispatch(addOfficeAction(office)),
  editOffice: (id, office) => dispatch(editOfficeAction(id, office)),
  deleteOffice: (officeId) => dispatch(deleteOfficeAction(officeId)),
});
