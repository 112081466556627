import { AnyAction, combineReducers } from "@reduxjs/toolkit";

import { protectedReducer, ProtectedState } from "./components/ProtectedComponent/reducer";
import { toastReducer, ToastState } from "./components/Toast/toastSlice";
import { countriesReducer, CountriesState } from "./pages/Countries/countriesSlice";
import { damageCostsReducer, DamageCostsState } from "./pages/DamageCosts/reducer";
import { dropoutsReducer, DropoutsState } from "./pages/Dropouts/reducer";
import { configKeysSlice } from "./pages/EditConfigKeysPage";
import { ConfigKeysState } from "./pages/EditConfigKeysPage/types";
import { importPageReducer, ImportPageState } from "./pages/ImportPage/reducer";
import { loginReducer, LoginState } from "./pages/Login/reducer";
import { officesReducer, OfficesState } from "./pages/Offices/reducer";
import { percentagesReducer, PercentagesState } from "./pages/ProposalPercentage/reducer";
import { languagesReducer, LanguagesState } from "./pages/Translations/languagesSlice";
import { usersReducer, UsersState } from "./pages/Users/reducer";

export interface AppState {
  protected: ProtectedState;
  percentages: PercentagesState;
  languages: LanguagesState;
  login: LoginState;
  users: UsersState;
  damageCosts: DamageCostsState;
  countries: CountriesState;
  dropouts: DropoutsState;
  offices: OfficesState;
  importPage: ImportPageState;
  toast: ToastState;
  configKeys: ConfigKeysState;
}

const appReducer = combineReducers<AppState | undefined>({
  protected: protectedReducer,
  percentages: percentagesReducer,
  languages: languagesReducer,
  login: loginReducer,
  users: usersReducer,
  damageCosts: damageCostsReducer,
  countries: countriesReducer,
  dropouts: dropoutsReducer,
  offices: officesReducer,
  importPage: importPageReducer,
  toast: toastReducer,
  configKeys: configKeysSlice.reducer,
});

export const rootReducer = (state: AppState | undefined, action: AnyAction) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state as AppState, action);
};
