import { withStyles } from "@material-ui/core";
import { compose } from "recompose";

import { protectedComponentHoc } from "../../components/ProtectedComponent";
import { ImportPageComponent, ImportPageInnerProps, ImportPageProps } from "./component";
import { ImportPageStyleRules } from "./styles";

export const ImportPage = compose<ImportPageInnerProps, ImportPageProps>(
  protectedComponentHoc,
  withStyles(ImportPageStyleRules)
)(ImportPageComponent);
