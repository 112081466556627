import { withStyles } from "@material-ui/core";
import { compose } from "recompose";

import { protectedComponentHoc } from "../../components/ProtectedComponent";
import { HomeComponent, HomeInnerProps, HomeProps } from "./component";
import { HomeStyleRules } from "./styles";

export const Home = compose<HomeInnerProps, HomeProps>(
  withStyles(HomeStyleRules),
  protectedComponentHoc
)(HomeComponent);
