import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  nav: {
    background: "#2C3E50",
    padding: "10px 16px",
  },
  holderLinks: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "center",
    height: "70px",
  },
});

export default useStyles;
