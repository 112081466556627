import useAxios from "axios-hooks";
import * as React from "react";
import { useDispatch } from "react-redux";

import { showSuccessAction } from "../../components/Toast/toastSlice";
import ApiClient from "../../services/ApiClient/api-client-services";
import { Action } from "../../types/useReducer";
import { Feedback, feedbackReducer, initialFeedback } from "../../utils/feedbackReducer";

const SET_LIMIT_URL = "insured/set-insured-limit";
const POLICY_TYPES_URL = "policies/types";
const USERS_URL = "users";

interface SetLimitRequest {
  insuredId: string;
  policyTypeId: string;
  amount: string;
}

interface Limits {
  [key: string]: string;
}

interface PolicyType {
  id: number;
  name: string;
}

interface User {
  id: string;
  firstName: string;
  lastName: string;
}

export interface UseAmountLimitOutput {
  feedback: Feedback;
  limits: Limits;
  policyTypes: PolicyType[];
  selectedUsers: User[];
  users: User[];
  handleChangeLimit: (payload: Limits) => void;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  setSelectedUsers: React.Dispatch<React.SetStateAction<User[]>>;
}

const reducer = (state: Limits, action: Action<"AMOUNT", Limits>): Limits => {
  switch (action.type) {
    case "AMOUNT": {
      return {
        ...state,
        ...action["payload"],
      };
    }
    default:
      throw new Error();
  }
};

export const useAmountLimit = (): UseAmountLimitOutput => {
  const [{ data: policyTypes = [] }] = useAxios<PolicyType[]>(POLICY_TYPES_URL);
  const [{ data: users = [] }] = useAxios<User[]>(USERS_URL);
  const initialLimits: Limits = Array.isArray(policyTypes)
    ? policyTypes.reduce((acc: Limits, { id }) => {
        acc[id] = "";
        return acc;
      }, {})
    : {};
  const [selectedUsers, setSelectedUsers] = React.useState<User[]>([]);
  const [limits, dispatchLimits] = React.useReducer(reducer, initialLimits);
  const [feedback, dispatchFeedback] = React.useReducer(feedbackReducer, initialFeedback);

  const dispatch = useDispatch();

  const saveLimit = (formData: SetLimitRequest) => {
    return ApiClient.createAxiosClient().post(
      ApiClient.createURL(SET_LIMIT_URL),
      formData,
      ApiClient.createAuthHeaders()
    );
  };

  const handleChangeLimit = (payload: Limits) => {
    dispatchLimits({
      type: "AMOUNT",
      payload,
    });
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const amounts = Object.entries(limits).filter(([policyTypeId, amount]) => amount !== "");
    if (amounts.length === 0 || selectedUsers.length === 0) return;

    const requestList: SetLimitRequest[] = selectedUsers
      .map((user) =>
        amounts.map(([policyTypeId, amount]) => ({
          insuredId: user.id,
          policyTypeId,
          amount,
        }))
      )
      .flat();

    dispatchFeedback({ type: "PENDING" });

    Promise.allSettled(requestList.map(saveLimit)).then((results) => {
      const successMessage = "Amount Limits set";
      const errorMessage = "Some errors were encountered";
      const hasErrors = results.some((result) => result.status !== "fulfilled");
      if (hasErrors) {
        dispatchFeedback({ type: "FULFILLED", payload: errorMessage });
      } else {
        dispatch(showSuccessAction(successMessage));
        dispatchFeedback({ type: "RESET" });
      }
    });
  };

  return {
    feedback,
    limits,
    policyTypes: Array.isArray(policyTypes) ? policyTypes : [],
    selectedUsers,
    users: Array.isArray(users) ? users : [],
    handleChangeLimit,
    onSubmit,
    setSelectedUsers,
  };
};
