import { createAction } from "redux-actions";

import ApiClient from "../../services/ApiClient/api-client-services";
import { Action } from "../../types/redux";
import { AddUserResponse, HerstelPartner, Makelaar, UserRawResponse, UserRequest, UserResponse } from "./models";

export enum ActionTypePrefixes {
  USERS = "USERS",
  OFFICES = "OFFICES",
  ADD_USER = "ADD_USER",
  DELETE_USER = "DELETE_USER",
  EDIT_USER = "EDIT_USER",
}

export enum ActionTypeKeys {
  USERS_PENDING = "USERS_PENDING",
  USERS_FULFILLED = "USERS_FULFILLED",
  USERS_REJECTED = "USERS_REJECTED",
  ADD_USER_PENDING = "ADD_USER_PENDING",
  ADD_USER_FULFILLED = "ADD_USER_FULFILLED",
  ADD_USER_REJECTED = "ADD_USER_REJECTED",
  DELETE_USER_PENDING = "DELETE_USER_PENDING",
  DELETE_USER_FULFILLED = "DELETE_USER_FULFILLED",
  DELETE_USER_REJECTED = "DELETE_USER_REJECTED",
  EDIT_USER_PENDING = "EDIT_USER_PENDING",
  EDIT_USER_FULFILLED = "EDIT_USER_FULFILLED",
  EDIT_USER_REJECTED = "EDIT_USER_REJECTED",
  MAKELAARS_PENDING = "MAKELAARS_PENDING",
  MAKELAARS_FULFILLED = "MAKELAARS_FULFILLED",
  MAKELAARS_REJECTED = "MAKELAARS_REJECTED",
  HERSTELERS_PENDING = "HERSTELERS_PENDING",
  HERSTELERS_FULFILLED = "HERSTELERS_FULFILLED",
  HERSTELERS_REJECTED = "HERSTELERS_REJECTED",
}

type GetMakelaarsPayload = Makelaar[];

type GetHerstelPartnersPayload = HerstelPartner[];

interface GetAllUsersPayload {
  data: UserRawResponse[];
}

interface AddUserPayload {
  data: AddUserResponse;
}

interface EditUserPayload {
  data: number[];
  config: {
    data: string;
  };
}

interface DeleteUserPayload {
  data: object;
}

interface DeleteMetaPayload {
  id: string;
}

const getAllUsersExecutor = (): Promise<UserRawResponse[]> => ApiClient.getAllUsers();

const addUserExecutor = (user: UserRequest): Promise<UserResponse> => ApiClient.addUser(user);

const deleteUserExecutor = (id: string) => ApiClient.deleteUser(id);
const deleteMetaCreator = (id: string) => ({ id });

const updateUserExecutor = (id: number, user: UserRequest): Promise<UserResponse> => ApiClient.updateUser(id, user);

export const getAllUsersAction = createAction(ActionTypePrefixes.USERS, getAllUsersExecutor);

export const addUserAction = createAction(ActionTypePrefixes.ADD_USER, addUserExecutor);

export const deleteUserAction = createAction(ActionTypePrefixes.DELETE_USER, deleteUserExecutor, deleteMetaCreator);

export const editUserAction = createAction(ActionTypePrefixes.EDIT_USER, updateUserExecutor);

export type ActionTypes =
  | Action<ActionTypeKeys.USERS_PENDING, GetAllUsersPayload>
  | Action<ActionTypeKeys.USERS_FULFILLED, GetAllUsersPayload>
  | Action<ActionTypeKeys.USERS_REJECTED, GetAllUsersPayload>
  | Action<ActionTypeKeys.ADD_USER_PENDING, undefined>
  | Action<ActionTypeKeys.ADD_USER_FULFILLED, AddUserPayload>
  | Action<ActionTypeKeys.ADD_USER_REJECTED, AddUserPayload>
  | Action<ActionTypeKeys.DELETE_USER_PENDING, undefined>
  | Action<ActionTypeKeys.DELETE_USER_FULFILLED, DeleteUserPayload, DeleteMetaPayload>
  | Action<ActionTypeKeys.DELETE_USER_REJECTED, GetAllUsersPayload>
  | Action<ActionTypeKeys.EDIT_USER_PENDING, undefined>
  | Action<ActionTypeKeys.EDIT_USER_FULFILLED, EditUserPayload>
  | Action<ActionTypeKeys.EDIT_USER_REJECTED, GetAllUsersPayload>
  | Action<ActionTypeKeys.HERSTELERS_PENDING, GetHerstelPartnersPayload>
  | Action<ActionTypeKeys.HERSTELERS_FULFILLED, GetHerstelPartnersPayload>
  | Action<ActionTypeKeys.HERSTELERS_REJECTED, GetHerstelPartnersPayload>
  | Action<ActionTypeKeys.MAKELAARS_PENDING, GetMakelaarsPayload>
  | Action<ActionTypeKeys.MAKELAARS_FULFILLED, GetMakelaarsPayload>
  | Action<ActionTypeKeys.MAKELAARS_REJECTED, GetMakelaarsPayload>;
