import { ActionTypeKeys, ActionTypes } from "../../data/types";

export interface LoginState {
  isAuthenticated: boolean;
  loading: boolean;
  role: string;
  token: string;
}

export const loginInitialState: LoginState = {
  isAuthenticated: false,
  loading: false,
  role: "",
  token: "",
};

export const loginReducer = (state: LoginState = loginInitialState, action: ActionTypes) => {
  switch (action.type) {
    case ActionTypeKeys.LOGIN_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypeKeys.LOGIN_FULFILLED: {
      return {
        ...state,
        loading: false,
        isAuthenticated: !!action.payload.data.token,
        token: action.payload.data.token,
      };
    }
    case ActionTypeKeys.LOGIN_REJECTED: {
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
      };
    }
    case ActionTypeKeys.LOGOUT: {
      return loginInitialState;
    }
    case ActionTypeKeys.VERIFY_TOKEN: {
      return {
        ...state,
        loading: false,
        role: action.payload.success ? action.payload.data.role : state.role,
      };
    }
    default:
      return state;
  }
};
