import React from "react";

import { ErrorBoundary } from "./components/ErrorBoundary";
import { Footer } from "./components/Footer";
import { Menu } from "./components/NavigationMenu";
import { Toast } from "./components/Toast";

const App: React.FC = () => {
  return (
    <div className="App">
      <ErrorBoundary>
        <Toast />
        <Menu />
        <Footer />
      </ErrorBoundary>
    </div>
  );
};

export default App;
