import * as React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";

import { DispatchProps, StateProps } from "./redux";

export interface ProtectedComponentProps extends RouteComponentProps {}

export interface ProtectedComponentInnerProps extends ProtectedComponentProps, StateProps, DispatchProps {}

export const ProtectedComponent = <P extends ProtectedComponentInnerProps>(
  WrappedComponent: React.ComponentType<P>
) => {
  return class extends React.Component<ProtectedComponentInnerProps> {
    public static displayName = `ProtectedComponent(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

    componentDidMount() {
      const { token, publicKey, verifyToken } = this.props;
      if (token && publicKey) {
        verifyToken(token, publicKey);
      }
    }

    componentDidUpdate() {
      const { isTokenExpired, refreshToken, exchangeToken, showError, logout } = this.props;
      if (refreshToken === undefined) {
        showError("Token expired");
        logout();
      } else if (isTokenExpired) {
        exchangeToken(refreshToken);
      }
    }

    render(): JSX.Element {
      const { location, token, isTokenExpired } = this.props;

      if (!token && !isTokenExpired) {
        return <Redirect to={{ pathname: "/login", state: { from: location } }} />;
      }

      return <WrappedComponent {...(this.props as P)} />;
    }
  };
};
