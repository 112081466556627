import { WithStyles } from "@material-ui/core";
import React from "react";

import { HomeClassKey } from "./styles";

export interface HomeProps {}

export interface HomeInnerProps extends HomeProps, WithStyles<HomeClassKey> {}

export const HomeComponent: React.FC<HomeInnerProps> = () => {
  return <div>Homepage</div>;
};
