import { Grid, Typography, WithStyles } from "@material-ui/core";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Fileupload } from "../../components/FileUpload";
import { resetStateAction, uploadFileAction } from "./actions";
import { importPageSelector } from "./selectors";
import { ImportPageClassKey } from "./styles";

export interface ImportPageProps {
  pageTitle: string;
  uploadURL: string;
}

export interface ImportPageInnerProps extends ImportPageProps, WithStyles<ImportPageClassKey> {}

export const ImportPageComponent: React.FC<ImportPageInnerProps> = ({ pageTitle, uploadURL, classes }) => {
  const filesLimit = 1;
  const { pendingUpload } = useSelector(importPageSelector);
  const dispatch = useDispatch();

  const handleUpload = (files: File[]) => {
    dispatch(uploadFileAction(files[0], `upload/${uploadURL}`));
  };

  React.useEffect(() => {
    return () => {
      dispatch(resetStateAction());
    };
  }, [dispatch]);

  return (
    <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
      <Grid item xs={6}>
        <Typography className={classes.pageTitle} gutterBottom align="center" variant="h4" component="h5">
          {pageTitle}
        </Typography>
        <Fileupload filesLimit={filesLimit} handleUploadFiles={handleUpload} pending={pendingUpload} />
      </Grid>
    </Grid>
  );
};
