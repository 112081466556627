import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import useAxios from "axios-hooks";
import * as React from "react";

import { TRANSLATION_LABEL_URL } from "../../../services/ApiClient/urls";
import useStyles from "../styles";

const pattern = "^$|(^[a-zA-Z_][a-zA-Z0-9_]*)$"; // empty string or string with letters, underscore and numbers, cannot begin with number
const regexPattern = new RegExp(pattern);

export const AddTranslationLabel: React.FC<any> = (props) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [label, setLabel] = React.useState("");
  const [labelError, setLabelError] = React.useState<string | undefined>(undefined);
  const [description, setDescription] = React.useState("");
  const [{ loading, error }, createLabel] = useAxios(
    {
      url: TRANSLATION_LABEL_URL,
      method: "POST",
    },
    {
      manual: true,
    }
  );
  const [successMessage, setSuccessMessage] = React.useState("");

  const handleClick = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
    setSuccessMessage("");
    setLabel("");
    setDescription("");
  };

  const saveLabel = () => {
    if (label === "" || validateKey(label, regexPattern) !== undefined) return;

    setSuccessMessage("");

    createLabel({
      data: { label, description },
    }).then(({ data: { msg } }) => {
      if (msg === "ok") {
        setSuccessMessage(`${label} was added.`);
        setLabel("");
        setDescription("");
        props.onSave();
      }
    });
  };

  const validateKey = (key: string, pattern: RegExp) => {
    const isValid = pattern.test(key);

    return isValid ? undefined : "May contain letters, numbers (but not start with one) and underscores only.";
  };

  React.useEffect(() => {
    setLabelError(validateKey(label, regexPattern));
  }, [label, error]);

  return (
    <>
      <Button onClick={handleClick} size="small">
        Add Label
      </Button>
      <Dialog onClose={closeDialog} aria-labelledby="add-label-title" open={isOpen}>
        <DialogTitle id="add-label-title">Add a new label</DialogTitle>
        <DialogContent>
          <form
            autoComplete="off"
            className={classes.form}
            onSubmit={(e) => {
              e.preventDefault();
              saveLabel();
            }}
          >
            <TextField
              id="label"
              label="Label"
              margin="normal"
              fullWidth={true}
              error={!!labelError}
              helperText={labelError}
              value={label}
              inputProps={{
                pattern,
              }}
              onChange={(event) => {
                setLabel(event.target.value);
              }}
            />
            <TextField
              id="label-description"
              label="Description"
              helperText="optional"
              margin="normal"
              fullWidth={true}
              multiline={true}
              value={description}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            />
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </form>
          {loading && <CircularProgress />}
          {error && <Alert severity="error">{error.response?.data || error.message}</Alert>}
          {successMessage && <Alert>{successMessage}</Alert>}
        </DialogContent>
      </Dialog>
    </>
  );
};
