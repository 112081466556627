import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { showErrorAction } from "../../components/Toast/toastSlice";
import { AppState } from "../../reducers";
import {
  CONFIG_KEYS_BASE_URL,
  createConfigHeaders,
  deleteConfigKey,
  fetchConfigKeys,
  fetchOffices,
} from "./api-functions";
import { ConfigKeyInput, EditKeyInput } from "./types";

export const getAllOffices = createAsyncThunk("configKeys/getAllOffices", async (data, { getState }) => {
  const {
    login: { token },
  } = getState() as AppState;

  const offices = fetchOffices(token);

  return offices;
});

export const getConfigKeys = createAsyncThunk("configKeys/getConfigKeys", async (data, { getState }) => {
  const {
    login: { token },
  } = getState() as AppState;

  const configKeys = fetchConfigKeys(token);

  return configKeys;
});

export const editConfigKey = createAsyncThunk(
  "configKeys/editConfigKey",
  async (data: EditKeyInput, { getState, dispatch }) => {
    const {
      login: { token },
    } = getState() as AppState;

    const config = createConfigHeaders(token);

    const body: any = {};
    body[data.id] = data.data;

    return new Promise((resolve, reject) => {
      axios
        .put(`${CONFIG_KEYS_BASE_URL}`, body, config)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          const uniqueOfficeErrorMessage = "You cannot have the same key twice for the same office";
          const uniqueOfficeConstrain = "configuration_keys.unique_office_config_key";

          if (err.response.data.includes(uniqueOfficeConstrain)) {
            dispatch(showErrorAction(uniqueOfficeErrorMessage));
          }

          reject(err);
        });
    });
  }
);

export const addConfigKey = createAsyncThunk(
  "configKeys/addConfigKey",
  async (data: ConfigKeyInput, { getState, dispatch }) => {
    const {
      login: { token },
    } = getState() as AppState;

    const config = createConfigHeaders(token);

    const body: ConfigKeyInput[] = [];
    body.push(data);

    return new Promise((resolve, reject) => {
      axios
        .post(`${CONFIG_KEYS_BASE_URL}`, body, config)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          const uniqueOfficeErrorMessage = "You cannot have the same key twice for the same office";
          const uniqueOfficeConstrain = "configuration_keys.unique_office_config_key";

          if (err.response.data.message.includes(uniqueOfficeConstrain)) {
            dispatch(showErrorAction(uniqueOfficeErrorMessage));
          }

          reject(err);
        });
    });
  }
);

export const removeConfigKey = createAsyncThunk("configKeys/removeConfigKey", async (data: number, { getState }) => {
  const {
    login: { token },
  } = getState() as AppState;

  const configKeys = deleteConfigKey(token, data);

  return configKeys;
});
