import { Box, Button, CircularProgress, Container, TextField, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Autocomplete } from "@material-ui/lab";
import { AxiosResponse } from "axios";
import useAxios from "axios-hooks";
import * as React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { BasicTable } from "../../components/BasicTable";
import ApiClient from "../../services/ApiClient/api-client-services";
import { loginSelector } from "../Login/selectors";
import useStyles from "./styles";

const MAPPING_URL = "policies/check/mappings";
const INSURER_BASE_URL = "insurer";

const policyFields: Array<Extract<keyof Policy, string>> = [
  "id",
  "insurerName",
  "insurerCode",
  "branchCode",
  "policyFile",
  "policyId",
];
interface Policy {
  branchCode: string;
  id: string;
  insurerCode: string;
  insurerName: string;
  policyId: string;
  policyFile: string;
}

interface FinalPolicy {
  file: string;
  id: string;
}
interface Result {
  finalPolicy: FinalPolicy;
  policiesFound: Policy[];
}

interface Insurer {
  id: string;
  name: string;
  code: string;
  active: boolean;
}

const isResult = (result: Result | any): result is Result => {
  return (result as Result).finalPolicy !== undefined && (result as Result).policiesFound !== undefined;
};

export const PolicyMappingsComponent: React.FC<any> = () => {
  const classes = useStyles();
  const [{ data: insurers, loading, error }] = useAxios(INSURER_BASE_URL);
  const [insurer, setInsurer] = React.useState<Insurer | null>(null);
  const [branchCode, setBranchCode] = React.useState<string>("");
  const [conditionCodes, setConditionCodes] = React.useState<string>("");
  const [result, setResult] = React.useState<Result | null>(null);
  const { role } = useSelector(loginSelector);

  if (role !== "superadmin") {
    return <Redirect to={{ pathname: "/" }} />;
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (insurer === null) return;
    const formData = {
      branchCode,
      conditionCodes,
      insurerId: insurer.code,
    };
    ApiClient.createAxiosClient()
      .post(ApiClient.createURL(MAPPING_URL), formData, ApiClient.createAuthHeaders())
      .then((response: AxiosResponse<Result>) => {
        const { data } = response;
        if (typeof data === "object") setResult(data);
      });
  };

  const renderInsurer = (option: Insurer) => {
    const { name, code, active } = option;
    const activeIcon = active ? <CheckCircleIcon className={classes.status} fontSize="small" /> : null;
    return (
      <>
        {name} {code} {activeIcon}
      </>
    );
  };

  const renderResult = (result: Result | any) => {
    const { finalPolicy, policiesFound } = result;

    if (!isResult(result) || policiesFound.length === 0) {
      return (
        <Typography variant="body1" gutterBottom>
          No policies found and no default found
        </Typography>
      );
    }

    return (
      <>
        {policiesFound.length > 1 && <p>Final policy: {finalPolicy.id}</p>}
        <BasicTable rows={policiesFound} cols={policyFields} />
      </>
    );
  };

  return (
    <Container maxWidth="md">
      <Box my={3}>
        <Typography align="center" variant="h4" component="h1">
          Check Policy Mappings
        </Typography>
      </Box>
      <form className={classes.form} onSubmit={onSubmit}>
        <Box display="flex" flexWrap="wrap">
          <Box flexGrow={1} mr={2}>
            {error && <span>Could not load insurer list. Please refresh the page.</span>}
            {loading ? (
              <CircularProgress />
            ) : (
              <Autocomplete
                value={insurer}
                onChange={(event, value) => setInsurer(value)}
                id="insurer"
                options={Array.isArray(insurers) ? insurers : []}
                getOptionLabel={(option) => option.name + " " + option.code}
                renderOption={renderInsurer}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField {...params} required={true} label="Insurer" />}
              />
            )}
          </Box>
          <TextField
            id="branch-code"
            value={branchCode}
            onChange={(e) => setBranchCode(e.target.value)}
            label="Branch code"
            required={true}
          />
          <TextField
            id="condition-codes"
            value={conditionCodes}
            onChange={(e) => setConditionCodes(e.target.value)}
            label="Condition codes"
            helperText="Enter comma separated codes"
            required={true}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" color="primary" size="large" type="submit">
            Check
          </Button>
        </Box>
      </form>
      <Box>{result !== null && renderResult(result)}</Box>
    </Container>
  );
};
