import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Autocomplete } from "@material-ui/lab";
import * as React from "react";

import { capitalize } from "../../utils/converterFunctions";
import useStyles from "./styles";
import { useAmountLimit } from "./useAmountLimit";

export const AmountLimitComponent: React.FC<any> = () => {
  const classes = useStyles();
  const checkIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const { feedback, limits, policyTypes, selectedUsers, users, handleChangeLimit, onSubmit, setSelectedUsers } =
    useAmountLimit();

  const renderAmountFields = policyTypes.map((policyType) => {
    const { name } = policyType;
    if (!name) return null;
    const capName = capitalize(name);

    const id = policyType.id.toString();

    return (
      <FormControl key={id} fullWidth={true} className={classes.row}>
        <InputLabel htmlFor={id}>{capName}</InputLabel>
        <Input
          id={id}
          type="number"
          value={limits[id]}
          onChange={(event) => {
            handleChangeLimit({ [id]: event.target.value });
          }}
          startAdornment={<InputAdornment position="start">€</InputAdornment>}
        />
      </FormControl>
    );
  });

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography align="center" variant="h4" component="h1">
          Amount Limits
        </Typography>
      </Box>
      <form className={classes.form} onSubmit={onSubmit}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12} sm={8}>
            <Autocomplete
              id="users"
              multiple={true}
              disableCloseOnSelect={true}
              value={selectedUsers}
              onChange={(event, value) => {
                setSelectedUsers(value);
              }}
              options={users}
              getOptionLabel={({ firstName, lastName }) => firstName + " " + lastName}
              renderOption={({ firstName, lastName }, { selected }) => (
                <>
                  <Checkbox icon={checkIcon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {firstName + " " + lastName}
                </>
              )}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => <TextField {...params} label="Test Users" />}
            />
          </Grid>
          <Grid item={true} xs={12} sm={4}>
            {renderAmountFields}
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" color="primary" size="large" type="submit">
            Save
          </Button>
        </Box>
      </form>
      <Typography align="center" variant="body1">
        {feedback.message}
      </Typography>
      <Backdrop open={feedback.isPending}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};
