import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    maxWidth: 300,
    width: "100%",
  },
  selectedItems: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
    width: "100%",
  },
}));

export default useStyles;
