import { ActionTypeKeys, ActionTypes } from "./actions";
import { DropoutRawResponse, DropoutResponse, Policy } from "./models";

export interface DropoutsState {
  dropouts: DropoutResponse[];
  isLoading: boolean;
  policyTypes: Policy[];
}

export const countriesInitialState: DropoutsState = {
  dropouts: [],
  isLoading: false,
  policyTypes: [],
};

const transformResponse = (dropouts: DropoutRawResponse[]): DropoutResponse[] =>
  dropouts.map((item) => {
    const user = JSON.parse(JSON.stringify(item));
    if (!user.policy_types) {
      user.policy_types = [];
    } else {
      user.policy_types = user.policy_types.map((policyType: Policy) => policyType.id);
    }
    return user;
  });

const addDropout = (dropouts: DropoutResponse[], data: DropoutRawResponse[]) => {
  // TODO
  console.log(data);
  return dropouts;
};

const editDropout = (dropouts: DropoutResponse[], requestData: string) => {
  const dropout = JSON.parse(requestData);
  const id = parseInt(dropout.id);

  const index = dropouts.findIndex((el) => el.id === id);
  if (index === -1) {
    return dropouts;
  }

  const updatedDropouts = dropouts.slice();
  updatedDropouts[index] = dropout;

  return updatedDropouts;
};

const deleteDropout = (dropouts: DropoutResponse[], id: number) => {
  if (!id) {
    return dropouts;
  }

  const index = dropouts.findIndex((el) => el.id === id);
  if (index === -1) {
    return dropouts;
  }

  const dropoutsLeft = dropouts.slice();
  dropoutsLeft.splice(index, 1);

  return dropoutsLeft;
};

export const dropoutsReducer = (state: DropoutsState = countriesInitialState, action: ActionTypes) => {
  switch (action.type) {
    case ActionTypeKeys.DROPOUTS_PENDING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ActionTypeKeys.DROPOUTS_FULFILLED: {
      return {
        ...state,
        dropouts: Array.isArray(action.payload.data) ? transformResponse(action.payload.data) : state.dropouts,
        isLoading: false,
      };
    }
    case ActionTypeKeys.ADD_DROPOUT_FULFILLED: {
      return {
        ...state,
        dropouts: addDropout(state.dropouts, action.payload.data),
      };
    }
    case ActionTypeKeys.EDIT_DROPOUT_FULFILLED: {
      return {
        ...state,
        dropouts: editDropout(state.dropouts, action.payload.config.data),
      };
    }
    case ActionTypeKeys.DELETE_DROPOUT_FULFILLED: {
      return {
        ...state,
        dropouts: deleteDropout(state.dropouts, action.meta.id),
      };
    }
    case ActionTypeKeys.POLICY_TYPES_FULFILLED: {
      return {
        ...state,
        policyTypes: Array.isArray(action.payload.data) ? action.payload.data : state.policyTypes,
      };
    }
    default:
      return state;
  }
};
