import { Dispatch } from "redux";

import { getOfficesAction } from "../../data";
import { AsyncDispatch } from "../../types/redux";
import { addUserAction, deleteUserAction, editUserAction, getAllUsersAction } from "./actions";
import { UserRequest } from "./models";
import { UsersState } from "./reducer";

export interface DispatchProps {
  getUsers: () => void;
  getOffices: () => void;
  addUser: (user: UserRequest) => Promise<AsyncDispatch>;
  deleteUser: (id: string) => Promise<AsyncDispatch>;
  editUser: (id: number, user: UserRequest) => Promise<AsyncDispatch>;
}

export interface StateProps extends UsersState {}

export const mapStateToProps = (usersState: UsersState): StateProps => {
  return {
    makelaars: usersState.makelaars,
    herstelPartners: usersState.herstelPartners,
    users: usersState.users,
    offices: usersState.offices,
    isLoading: usersState.isLoading,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  getUsers: () => {
    dispatch(getAllUsersAction());
  },
  addUser: (user) => dispatch(addUserAction(user)),
  editUser: (id, user) => dispatch(editUserAction(id, user)),
  deleteUser: (id) => dispatch(deleteUserAction(id)),
  getOffices: () => {
    dispatch(getOfficesAction());
  },
});
