import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import useAxios from "axios-hooks";
import * as React from "react";
import { useRouteMatch } from "react-router-dom";

import { INSURED_URL } from "../../../../services/ApiClient/urls";
interface PageId {
  id: string;
}

const InsurerComponent: React.FC<any> = () => {
  const match = useRouteMatch<PageId>("/insurer/:id");
  const [{ data, loading, error }] = useAxios(INSURED_URL + "/" + match?.params.id);
  const insuredData = data?.insuredData || {};

  return (
    <Box display="flex" flexWrap="nowrap" p={1} m={1}>
      {error && <span>There was an error</span>}
      {loading ? (
        <Box textAlign="center" mb={3}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h2" component="h2">
              {insuredData.firstName} {insuredData.lastName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h6" component="h6">
              {insuredData.email}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default InsurerComponent;
