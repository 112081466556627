import { Action } from "../types/useReducer";

type ActionTypes = "PENDING" | "FULFILLED" | "RESET";

export const initialFeedback = {
  isPending: false,
  message: "",
};

export type Feedback = typeof initialFeedback;

export const feedbackReducer = (state: Feedback, action: Action<ActionTypes, string>) => {
  switch (action.type) {
    case "PENDING":
      return {
        ...state,
        isPending: true,
      };
    case "FULFILLED":
      return {
        isPending: false,
        message: action.payload || "",
      };
    case "RESET":
      return initialFeedback;
    default:
      throw new Error();
  }
};
