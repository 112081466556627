import { Box, CircularProgress, Container, IconButton, Typography } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import useAxios from "axios-hooks";
import * as React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { loginSelector } from "../Login/selectors";

const MISSING_POLICIES_DB = "policies/check/policies";

const PolicyDBComponent: React.FC<any> = () => {
  const [{ data, loading, error }, refetch] = useAxios(MISSING_POLICIES_DB);
  const { role } = useSelector(loginSelector);

  if (role !== "superadmin") {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <Container>
      <Box my={3} display="flex" flexDirection="column" alignItems="center">
        <Typography align="center" variant="h4" component="h1">
          Check Policies in DB
        </Typography>
        {error && <span>There was an error</span>}
        <Box alignSelf="flex-end">
          <IconButton
            aria-label="refresh"
            onClick={() => {
              refetch();
            }}
          >
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>
      {loading ? (
        <Box textAlign="center" mb={3}>
          <CircularProgress />
        </Box>
      ) : (
        <ul>{Array.isArray(data) && data.map((policy, index) => <li key={index}>{policy}</li>)}</ul>
      )}
    </Container>
  );
};

export default PolicyDBComponent;
