import "./index.css";

import axios from "axios";
import { configure } from "axios-hooks";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import { expireAction } from "./data/authenticate";
import { protectedComponentStoreStateSelector } from "./selectors";
import ApiClient from "./services/ApiClient/api-client-services";
import * as serviceWorker from "./serviceWorker";
import { persistor, store } from "./store";

const rootElement = document.getElementById("root") as HTMLElement;

const axiosInstance = axios.create({
  baseURL: ApiClient.getBaseUrl() + "/external-api/",
});

store.subscribe(() => {
  const state = store.getState();
  const { token } = protectedComponentStoreStateSelector(state);
  if (token) {
    ApiClient.setToken(token);
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      store.dispatch(expireAction());
    }

    return Promise.reject(error);
  }
);

configure({ axios: axiosInstance }); // instance for axios hooks

// @ts-ignore
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,

  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
