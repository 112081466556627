import { Typography, WithStyles } from "@material-ui/core";
import { Column } from "material-table";
import React, { useEffect, useState } from "react";

import { Table } from "../../components/Table";
import { Policy } from "../Dropouts/models";
import { PercentageResponse } from "./models";
import { DispatchProps, StateProps } from "./redux";
import { PercentagesClassKey } from "./styles";

export interface PercentagesProps {}

export interface PercentagesInnerProps
  extends PercentagesProps,
    StateProps,
    DispatchProps,
    WithStyles<PercentagesClassKey> {}

interface ColumnsState {
  columns: Column<PercentageResponse>[];
}

export const PercentageComponent: React.FC<PercentagesInnerProps> = ({
  getPolicyTypes,
  editPercentage,
  percentages,
  isLoading,
  classes,
}) => {
  const [columnsState] = useState<ColumnsState>({
    columns: [
      { field: "id", title: "ID", editable: "never", filtering: false },
      { field: "name", title: "Name", filtering: false, editable: "never" },
      {
        field: "percentage",
        title: "Percentage",
        filtering: false,
        editable: "always",
        validate: (rowData: Policy) => !(rowData.percentage && rowData.percentage > 1),
      },
    ],
  });

  useEffect(() => {
    getPolicyTypes();
  }, [getPolicyTypes]);

  const handleEditPercentage = (percentageNewData: Policy) => editPercentage(percentageNewData);

  return (
    <>
      <Typography className={classes.pageTitle} gutterBottom align="center" variant="h4" component="h5">
        Percentages
      </Typography>
      <Table
        hasEdit={true}
        hasDelete={false}
        handleEditRow={handleEditPercentage}
        dataTable={percentages}
        columnsData={columnsState.columns}
        isLoading={isLoading}
      />
    </>
  );
};
