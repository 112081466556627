import { Box } from "@material-ui/core";
import React from "react";

interface FooterProps {}

interface FooterInnerProps extends FooterProps {}

export const FooterComponent: React.FC<FooterInnerProps> = (props) => {
  const build = process.env.REACT_APP_BUILD_NUMBER;

  return <Box m={2}>Version {build}</Box>;
};
