import { LookupItemDataType } from "../Users/models";

export const dataTypeErrorMessage = `The Data Type must be: "int", "bool" or "string"!`;
export const emptyFieldsErrorMessage = `Please fill out all the input fields!`;
export const dataTypeItems: LookupItemDataType = {
  int: "int",
  bool: "bool",
  string: "string",
};

export const CONFIG_KEYS_URL_EXTENSION = "configuration-key";
export const CONFIG_KEYS_URL_LOCAL = "https://develop-docker.emsclaimsengine.com/external-api/configuration-key";

export const OFFICE_URL_EXTENSION = "office";
export const OFFICE_URL_LOCAL = "https://develop-docker.emsclaimsengine.com/external-api/office";
